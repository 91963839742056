@tailwind base;
@tailwind components;
@tailwind utilities;

/* audio::-webkit-media-controls-mute-button {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
} */

audio::-webkit-media-controls-panel {
  -webkit-justify-content: center;
  height: '25px';
  background-color: #fff;
}

/* Removes the timeline */
audio::-webkit-media-controls-timeline {
  display: none !important;
}

/* Removes the time stamp */
/* audio::-webkit-media-controls-current-time-display {
  display: none;
}
audio::-webkit-media-controls-time-remaining-display {
  display: none;
} */
